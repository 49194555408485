
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ILink } from '../../shared/general/interfaces/ILink'

@Component({
  name: 'ButtonLinkOverview',
  components: { BaseLink: () => import('../base/BaseLink.vue') },
})
export default class ButtonLinkOverview extends Vue {
  @Prop({ required: true }) elements! : ILink[]

  @Prop({ default: 'center' }) alignment! : string

  private styles (button ?: ILink) : string {
    return button?.type?.includes('Store') ? 'w-36 h-auto justify-center' : ''
  }

  justify = {
    left: 'justify-start',
    right: 'justify-end',
    center: 'justify-center',
  }
}
